import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConfigService } from './services/app-config.service';
import { MainService } from './services/main.service';
import { getLocalStorageItem, setLocalStorageItem, setSessionStorageItem } from './shared/utility/utility';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showNavbarOnRoutes: boolean;
  loggedIn=false;
  isDataLoaded: boolean=false;
  appConfig: any;
  currentUrl: any;
  enableAppConfig:any=false
  enableImgConfig:any=false


  constructor(private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,private mainService: AppConfigService,private service:MainService, private titleService: Title,){

      if(!getLocalStorageItem('language')){
        setLocalStorageItem('language','ENG')
        this.useLanguage('ENG');
        this.setDefaultLang('ENG') 
      }else{
        let setLang=getLocalStorageItem('language')
        this.useLanguage(setLang);
        this.setDefaultLang('ENG') 
      }
      console.log(window.location.href)
      if(environment.defaultOnwer=='echo'){
        setLocalStorageItem('access', 'private');
      }
      
  }

  ngOnInit(){
    this.service.data$.subscribe(res => this.loggedIn = res)
    
    if(!getLocalStorageItem('access') && environment.defaultOnwer!='echo'){
      setLocalStorageItem('access','public');
    }

    this.router.events.pipe(filter(e => e instanceof NavigationEnd))
    .subscribe((e: any) => {
      this.currentUrl = e.url;
      console.log('in')
      const formType = this.route.snapshot.queryParamMap.get('formType') ? 'ASSESSMENT_TYPE.' + this.route.snapshot.queryParamMap.get('formType')?.toUpperCase() : 'survey';
      const formSubType = this.route.snapshot.queryParamMap.get('formSubType');
      const subTypeLabel=this.route.snapshot.queryParamMap.get('subTypeLabel');
      const token = this.route.snapshot.queryParamMap.get('token');
      const imageToken = this.route.snapshot.queryParamMap.get('imgToken');
      const redirectURL = this.route.snapshot.queryParamMap.get('redirectURL');
      const language = this.route.snapshot.queryParamMap.get('language') ? this.route.snapshot.queryParamMap.get('language') : 'ENG';
      const data = this.route.snapshot.queryParamMap.get('data');
      const aid = this.route.snapshot.queryParamMap.get('assessmentId');
      const domain=this.route.snapshot.queryParamMap.get('domain')
      const subdomain=this.route.snapshot.queryParamMap.get('subdomain')
      const hideAssesseInfo=this.route.snapshot.queryParamMap.get('hideAssesseInfo')
      const surveyInstanceId=this.route.snapshot.queryParamMap.get('surveyInstanceId')
      const assesseeName=this.route.snapshot.queryParamMap.get('assessmentCreatorName')
      const assessedForEntityType=this.route.snapshot.queryParamMap.get('assessedForEntityType')
      const assesseeActorId=this.route.snapshot.queryParamMap.get('assessmentCreatorId')
      const assessedForEntityId=this.route.snapshot.queryParamMap.get('assessedForEntityId')
      const assessmentActorId=this.route.snapshot.queryParamMap.get('assessmentActorId')
      const forEntityId=this.route.snapshot.queryParamMap.get('forEntityId')
      const forEntityType=this.route.snapshot.queryParamMap.get('forEntityType')
      let lanCode = this.route.snapshot.queryParamMap.get('langCode') ? this.route.snapshot.queryParamMap.get('langCode') : 'ENG';
      console.log(forEntityId,forEntityType);

      const isCreateNew = this.route.snapshot.queryParamMap.get('copyFromAssessmentInstanceId') ? 'true' : 'false';
      if(this.route.snapshot.queryParamMap.get('token')){
        setLocalStorageItem('token', token);
        setSessionStorageItem('domain', domain);
        setLocalStorageItem('subdomain', subdomain);
        setLocalStorageItem('form-language', language);
        setLocalStorageItem('language', language);
        setLocalStorageItem('data', data);
        setLocalStorageItem('imageToken', imageToken);
        setLocalStorageItem('isCreateNew', isCreateNew);
        setLocalStorageItem('formType', formType);
        setLocalStorageItem('formSubType', formSubType);
        setLocalStorageItem('redirectURL', redirectURL);
        setLocalStorageItem('access', 'private');
        setLocalStorageItem('hiddenVal', hideAssesseInfo);
        setLocalStorageItem('assesseeName', assesseeName);
        setLocalStorageItem('assessedForEntityType', assessedForEntityType);
        setLocalStorageItem('assessmentActorId', assessmentActorId);
        setSessionStorageItem('assesseAID',assesseeActorId ,true)
        setSessionStorageItem('assessedEID',assessedForEntityId,true);
        setSessionStorageItem('language', lanCode,true)
        setLocalStorageItem('appVersion','0.0.5')
        setLocalStorageItem('forEntityId',forEntityId)
        setLocalStorageItem('forEntityType',forEntityType)

        let currentRoute = this.router.routerState.snapshot.url;
        console.log(currentRoute)
        let pathWithoutParams = currentRoute.split('?')[0];
        if(domain){
          let urlTemp=pathWithoutParams.split('/');
          let newValue:any=domain.toLowerCase().split('.').pop()
          urlTemp[urlTemp.length - 1] = newValue;
          pathWithoutParams = urlTemp.join('/');
        }else if(aid){
          let url='/evaluation/view/'+aid
          pathWithoutParams=url
        }
        this.router.navigate([pathWithoutParams]);

      }

    this.getImages()

    this.mainService.getConfigJSON('json/static-data/' + 'app-config').subscribe({
      next: (appConfig: any) => {

        this.mainService.getConfigService('CONFIG').subscribe({
          next: (serviceAppConfig: any) => {
            let mergedData = Object.assign({}, appConfig, serviceAppConfig);
            
            this.mainService.setAppConfig(mergedData);
            this.appConfig=mergedData;
            this.setSubDomain();
            this.titleService.setTitle(this.appConfig['SURVEY_APP_CONFIG.APP_TITLE']);
            this.preLoadCSS(this.appConfig['SURVEY_APP_CONFIG.THEME_NAME']);
            this.getLabels();
            if(getLocalStorageItem('access')=='private'){
              this.service.fetchUserProfile()
            }
            
            this.enableAppConfig=true
            this.isDataLoaded=true
          }
          , error: (error: any) => {
            if (error.status == 404) {
              this.mainService.setAppConfig(appConfig);
              
            }
            
            this.enableAppConfig=true
            this.isDataLoaded=true

            console.log('unable to fetch config details')
            throw error
          }
        })
      },
      error: err=>{
        throw { error: err};
      }})
    });

    this.changeOfRoutes();

  }

  setSubDomain() {
    if(this.appConfig?.['SURVEY_APP_CONFIG.HIDE_SUB_DOMAIN'] == '1'){
      setLocalStorageItem('subdomain',this.appConfig?.['SURVEY_APP_CONFIG.DEFAULT_SUB_DOMAIN_CODE']);
    }
  }

  getImages(){
    this.mainService.getConfigJSON('json/images').subscribe({
      next: (labels: any) => {
        this.mainService.getConfigService('IMAGE').subscribe({
          next: (serviceLabels: any) => {
            let mergedData = Object.assign({}, labels, serviceLabels);
            // console.log(mergedData)
            this.service.setImage(mergedData)
            if (mergedData['SURVEY_APP.FAVICON']) {
              let favicon = document.createElement('link');
              favicon.href = mergedData['SURVEY_APP.FAVICON'];
              favicon.rel = 'icon';
              favicon.type = 'image/x-icon';
              document.head.append(favicon);
            }
            this.enableImgConfig=true

            
          },
          error: (error: any) => {
            throw error;
            
          }
        });
      },
      error: (error: any) => {
        throw error;
        this.enableImgConfig=true
      }
      
    });
  }

  preLoadCSS(themeName?: string) {
    //let fontThemeLink = document.getElementById('fontThemeLink');
    //fontThemeLink?.setAttribute('href', './assets/theme/fonts.css');
    let variableThemeLink = document.getElementById('variableThemeLink');
    //variableThemeLink?.setAttribute('href', `./assets/theme/variables-theme-two.css`);

    variableThemeLink?.setAttribute('href', `./assets/theme/${themeName}.css`);
  }

  changeOfRoutes() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        if(this.appConfig){
              this.titleService.setTitle(this.appConfig['SURVEY_APP_CONFIG.APP_TITLE']);
        }

        if (event['url'].startsWith('/evaluation') || event['url'].startsWith('/login') || event['url'].startsWith('/quest-wizard') || 
        event['url'].startsWith('/home/preview')) {
          this.showNavbarOnRoutes = false;
           // console.log('showfalse')
        } else {
          this.showNavbarOnRoutes = true;
        }
        
      }
    });
  }

  getLabels() {
    //this.dataLoaded = false;
    this.mainService.getConfigJSON('json/i18n/' + getLocalStorageItem('language')).subscribe({
      next: (labels: any) => {
        this.mainService.getConfigService('LABEL').subscribe({
          next: (serviceLabels: any) => {
            let mergedData = Object.assign({}, labels, serviceLabels);
            // console.log(mergedData)
            this.translate.setTranslation(getLocalStorageItem('language'),mergedData, false)
            this.isDataLoaded=true
            
          },
          error: (error: any) => {
            throw error;
          }
        });
      },
      error: (error: any) => {
        throw error;
      }
    });
  }

  useLanguage(lang:string){
    this.translate.use(lang);
  }

  setDefaultLang(lang:string){
    this.translate.setDefaultLang(lang);
  }
}
