import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SurveyService } from '../services/takerServices/survey.service';
import { getSessionStorageItem, setLocalStorageItem, setSessionStorageItem, setSessionStorageItemEncrypt } from '../shared/utility/utility';

@Injectable({
  providedIn: 'root'
})
export class QuizUserAuthGuard implements CanActivate {
  constructor(private router: Router, private service: SurveyService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    console.log('url', route.queryParams)
    if (route.queryParams['surveyInstanceId']) {
      let lanCode = route.queryParams['langCode'] ? route.queryParams['langCode'] : 'ENG';
      setSessionStorageItem('language', lanCode,true)
      setSessionStorageItemEncrypt('cross-session-id', route.queryParams['token'],true)
      setSessionStorageItem('isStandalone', 'true',true)
      setSessionStorageItem('assessedEID', route.queryParams['assessedForEntityId'],true)
      setSessionStorageItem('assesseAID', route.queryParams['assesseeActorId'],true)
      setSessionStorageItem('surveyIID', route.queryParams['surveyInstanceId'],true)
      setSessionStorageItem('data', route.queryParams['data'],true);
      setSessionStorageItem('imgToken', route.queryParams['imgToken'],true);
      // setSessionStorageItem('assessedForEntityId', route.queryParams['assessedForEntityId'],true);
      setLocalStorageItem('assessedForEntityType', route.queryParams['assessedForEntityType']);
      setLocalStorageItem('token', route.queryParams['token']);
      setLocalStorageItem('assesseeName', route.queryParams['assesseeName'])
      setLocalStorageItem('imageToken',route.queryParams['imgToken'])
      if (route.queryParams['redirectUrl']) {
        setSessionStorageItem('redirectUrl', route.queryParams['redirectUrl'],true);
      }

      if (route.queryParams['token']) {
        setSessionStorageItem('isToken', 'true',true)
      }

      if (route.queryParams['assessorActorId']) {
        setSessionStorageItem('tenant_id', route.queryParams['assessorActorId'],true)
      }
      else {
        setSessionStorageItem('tenant_id', '0',true)
      }

      this.router.navigateByUrl('/quest-wizard/' + route.queryParams['surveyInstanceId'])
      return true
    } else {

      console.log(state.url.split('/')[2])
      let aidURL: any = Number(state.url.split('/')[2])
      setSessionStorageItem('surveyIID', aidURL,true)
      this.storeAppData()
    }
    return true
  }

  storeAppData() {
    let sessionCustomLang: any = getSessionStorageItem('language',true)
    if (sessionCustomLang) {
      setSessionStorageItem('language', sessionCustomLang,true)
    } else {
      setSessionStorageItem('language', 'ENG',true)
    }
    setSessionStorageItem('isStandalone', 'false',true)
    setSessionStorageItem('isToken', 'false',true)
    setSessionStorageItem('tenant_id', '0',true)
  }
}
