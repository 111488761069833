
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { getSessionStorageItem } from 'src/app/shared/utility/utility';
import { environment } from 'src/environments/environment';
import { AppConfigService } from '../app-config.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyService{

  constructor(private http: HttpClient,
    private appService:AppConfigService) { }
  getSurveyAttemptStatus(entityId: any, entityType: any, assessmentInstanceId: any) {
    return this.http.get(environment.baseUrl + '/assessment-service/api/v1/assessmentInstanceAssesses/assessedForEntityId/?assessedForEntityId=' + entityId + '&assessedForEntityType=' + entityType + '&assessmentInstanceId=' + assessmentInstanceId)
  }
  getListOfAssessmentInstancesOfSurveyType() {
    return this.http.get(environment.baseUrl + '/assessment-service/api/v1/assessmentinstances/assessment/?assessmentIsActive=true&assessmentType=ASSESSMENT_TYPE.SURVEY&isActive=true')
  }
  getAssessmentWithId(assessmentID: any) {
    return this.http.get(environment.baseUrl + '/assessment-service/api/v1/assessments/' + assessmentID);
  }
  getAssessmentInstanceWithAssessmentInstanceId(assessmentInstanceID: any) {
    return this.http.get(environment.baseUrl + '/assessment-service/api/v2/assessmentinstances/' + assessmentInstanceID);
  }
  getAssessmentQuestionPaperWithAssessmentID(assessmentID: any) {
    return this.http.get(environment.baseUrl + '/assessment-service/api/v2/assessmentQuestionPapers?instanceId=' + assessmentID);
  }
  getQuestionsWithIdList(questionIDList: any,aid:number) {
    let httpParams = new HttpParams();
    questionIDList.forEach((questionId: any) => {
      httpParams = httpParams.append('questionIds', questionId);
    });
    return this.http.get(environment.baseUrl + '/assessment-service/api/v2/questions/questions/instance/'+aid, { params: httpParams });
  }
  createAssessmentInstanceAssesses(aia: any) {
    return this.http.post(environment.baseUrl + '/assessment-service/api/v2/assessmentInstanceAssesses', aia);
  }

  invokeWebhookApi(data:any){
    let  appConfig:any =this.appService.getConfigData();

    return this.http.post(appConfig['SURVEY_APP_CONFIG.WEBHOOK_URL'],this.createParams(data))
  }

  historyData$ = new BehaviorSubject<any>('');
  historyDataAsObservable$ = this.historyData$.asObservable();
  setHistoryData(data: boolean) {
    this.historyData$.next(data);
  }

  data: any = '';
  setStoredData(data: any) {
    this.data = data;
  }

  getData() {
    return this.data;
  }

  getJSON(fileName: string) {
    return this.http.get('assets/json/' + fileName + '.json');
  }
  fetchJson(type:any) {
    return this.http.get('assets/json/'+type + '.json');
  }

  postServiceBuilder(api: string, params: any, body: any, headers: any, extraParam?: any) {
    if (params != null) {
      return this.post(api, body, this.createHeaders(headers), extraParam, this.createParams(params));
    }
    return this.post(api, body, this.createHeaders(headers), extraParam);
  }

  createHeaders(headers: any): HttpHeaders {
    let header = new HttpHeaders();
    for (const key of Object.keys(headers)) {
      header = header.append(key, headers[key]);
    }
    return header;
  }
  createParams(params: any): HttpParams {
    let param: any = new HttpParams();
    for (const key of Object.keys(params)) {
      param = param.set(key, params[key]);
    }
    return param;
  }

  post(apiName: string, body: any, header: HttpHeaders, observe?: any, params?: HttpParams): Observable<any> {
    let resp = {};
    if (observe !== undefined) {
      if (observe.hasOwnProperty('observeType')) {
        resp = {
          headers: header,
          // tslint:disable-next-line: no-string-literal
          observe: observe['observeType']
        };
      } else if (observe.hasOwnProperty('responseType')) {
        resp = {
          headers: header,
          // tslint:disable-next-line: no-string-literal
          responseType: observe['responseType'],
          observe: 'response'
        };
      }
    } else {
      resp = {
        headers: header,
        observe: 'response'
      };
    }
    return this.http.post(environment.baseUrl + apiName + (params ? params : ''), body, resp);
  }


  async uploadFile(assesmentSchema:any) {
    let  appConfig:any =this.appService.getConfigData();
    let url = appConfig['SURVEY_APP_CONFIG.IMAGE_URL']+'/upload/api/v1/files/save'
    let imgToken = getSessionStorageItem('imgToken',true);
    let formData: any = new FormData();
    formData.append("filePath",assesmentSchema.image );
    const authHeader: HttpHeaders = new HttpHeaders({
      Authorization: `${imgToken}`,
      imgTokenPresent:'true'
    });
    let promise = new Promise(async (resolve, reject) => {
      try{
        const data:any =await this.http.post(url, formData,{ headers: authHeader})
        .toPromise()
          console.log('formDataformDataformData', data);
          let output=data?.resultBody?.url
          resolve(output);
      } catch(error){
        console.log('test back')
          reject(error)
      }
    });
    return promise;
  }

  async convertImgtoUrl(imgPayload:any){
    let  appConfig:any =this.appService.getConfigData();
    if(environment.defaultOnwer=='echo'){
      return this.uploadFile(imgPayload)
    }else{
      const imageData: FormData = new FormData();
      imageData.append('file', imgPayload.image)

      let promise = new Promise(async (resolve, reject) => {
        await this.http.post(environment.baseUrl + '/assessment-service/api/v1/attachment/assessment?entityType=ENTITY_TYPE.ASSESSMENT_QUESTION', imageData,{'responseType':'text'})
        .toPromise()
          .then((data) => {
            console.log('formDataformDataformData', data);
            resolve(data);
          },(error)=>{
            console.log('test back')
            reject(error)
          })
      });
      return promise;
  }
}
}

