import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { getLocalStorageItem, setSessionStorageItem } from '../shared/utility/utility';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class PrivateAccessGuard implements CanActivate {
  constructor(private route:Router){
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let accessStatus = getLocalStorageItem('access')
    if (accessStatus && accessStatus == 'private') {
      return true
    }
    setSessionStorageItem('primary-url',window.location.href)
    this.route.navigate(['/login'])
    return false;
  }

}
